import React from "react";
import PreRegistration from "../../components/preRegistration";

function Registration() {
  return (
    <>
      <PreRegistration />
    </>
  );
}

export default Registration;
