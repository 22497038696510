import { Route, Routes, BrowserRouter } from "react-router-dom";
import Error from "../pages/error";
import PreRegistration from "../pages/preregistration/index";
import RegistrationConfirm from "../pages/registrationConfirm/index";
import Thanks from "../pages/thanks/index";
// import Home from "../pages/home/index";
// import WorkTable from "../pages/worktable/index";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PreRegistration />} />
        <Route path="/registrationConfirm" element={<RegistrationConfirm />} />
        <Route path="/thanks" element={<Thanks />} />
        <Route path="/error" element={<Error />} />
        <Route path="*" element={<Error />} />
      </Routes>
      {/* <Routes>
        <Route path="/" element={<Home />} />
      </Routes> */}
      {/* <Routes>
        <Route path="/preregistration" element={<PreRegistration />} />
      </Routes> */}
      {/* <Routes>
        <Route path="/worktable" element={<WorkTable />} />
      </Routes> */}
    </BrowserRouter>
    
  );
}

export default App;
