import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function PreRegistration() {
  const [valueEmail, setValueEmail] = useState("");
  const [valuePassword, setValuePassword] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");
  const [activeTabs, setActiveTabs] = useState(false);
  const [typeInputOne, setTypeInputOne] = useState(false);
  const [typeInputTwo, setTypeInputTwo] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState(false);
  const [passwoedErrorMessage, setPasswoedErrorMessage] = useState(false);
  const [activeLoader, setActiveLoader] = useState(false);
  const [countGuards, setCountGuards] = useState("0");
  const [countClients, setCountClients] = useState("0");
  const navigate = useNavigate();

  const validateEmail = (valueEmail) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(valueEmail).toLowerCase());
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (valuePassword !== confirmationPassword) {
      setPasswoedErrorMessage(true);
    } else {
      setPasswoedErrorMessage(false);
    }

    if (!validateEmail(valueEmail)) {
      setEmailErrorMessage(true);
    } else {
      setEmailErrorMessage(false);
    }

    if (valuePassword === confirmationPassword && validateEmail(valueEmail)) {
      setActiveLoader(true);
      const sendPostRequest = async () => {
        try {
          const response = await axios.post(
            "https://79.132.138.189:8090/api/v1/registration",
            {
              email: valueEmail,
              isCompany: activeTabs,
              password: valuePassword,
              matchingPassword: confirmationPassword,
            }
          );
          console.log(response);
          setActiveLoader(false);
          navigate("/thanks");
        } catch (error) {
          console.error(error);
          setActiveLoader(false);
          navigate("/error");
        }
      };

      sendPostRequest();
    }
  };

  const handleChangeEmail = (e) => {
    setValueEmail(e.target.value);
  };

  const handleChangePassword = (e) => {
    setValuePassword(e.target.value);
  };

  const handleChangeConfirmationPassword = (e) => {
    setConfirmationPassword(e.target.value);
  };

  useEffect(() => {
    const fetchСountGuards = async () => {
      try {
        const responseGuards = await axios.get(
          "https://79.132.138.189:8090/api/v1/user/countGuards"
        );
        const responseClients = await axios.get(
          "https://79.132.138.189:8090/api/v1/user/countClients"
        );
        setCountGuards(responseGuards.data);
        setCountClients(responseClients.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchСountGuards();
  }, []);

  return (
    <section className="registration">
      {activeLoader && (
        <div className="loader">
          <div className="loader__wrapper"></div>
        </div>
      )}
      <div className="registration__wrapper">
        <div className="registration__logo-wrapper">
          <div className="registration__logo">GUARD POINT</div>
        </div>

        <div className="registration__common-wrapper">
          <div className="registration__common-bg-wrapper">
            <div className="registration__mini--wrapper">
              <div className="intro__mini-wrapper intro__mini-wrapper-two">
                <ul className="intro__number-list">
                  <li className="intro__number-item intro__number-item--one">
                    <h3 className="intro__number-title">Geregistreerde klanten</h3>
                    <div className="intro__number">{countClients}</div>
                  </li>
                  <li className="intro__number-item">
                    <h3 className="intro__number-title">
                    Beveiligers geregistreerd
                    </h3>
                    <div className="intro__number">{countGuards}</div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="registration__big-wrapper">
              <h2 className="registration__big-title">Registratie</h2>

              <div className="registration__tabs-wrapper">
                <span
                  onClick={() => setActiveTabs((activeTabs) => !activeTabs)}
                  className={
                    activeTabs
                      ? "registration__tabs"
                      : "registration__tabs active"
                  }
                >
                  Beveiliger
                </span>
                <span
                  onClick={() => setActiveTabs((activeTabs) => !activeTabs)}
                  className={
                    activeTabs
                      ? "registration__tabs active"
                      : "registration__tabs"
                  }
                >
                  Opdrachtgever
                </span>
              </div>

              <div className="registration__input-wrapper">
                <div className="registration__input-mini-wrapper">
                  <input
                    value={valueEmail}
                    autoComplete="new-password"
                    onChange={handleChangeEmail}
                    className={
                      emailErrorMessage
                        ? "registration__input registration__input-error-border"
                        : "registration__input"
                    }
                    type="email"
                    placeholder="E-mail"
                  />
                  {emailErrorMessage && (
                    <span className="registration__input-error">
                      Onjuiste e-mail
                    </span>
                  )}
                </div>
                <div className="registration__input-mini-wrapper">
                  <input
                    value={valuePassword}
                    onChange={handleChangePassword}
                    autoComplete="new-password"
                    className={
                      passwoedErrorMessage
                        ? "registration__input registration__input-error-border"
                        : "registration__input"
                    }
                    type={typeInputOne ? "text" : "password"}
                    placeholder="Wachtwoord"
                  />
                  <span
                    onClick={() =>
                      setTypeInputOne((typeInputOne) => !typeInputOne)
                    }
                    className="registration__input-password-vision"
                  ></span>
                </div>
                <div className="registration__input-mini-wrapper">
                  <input
                    value={confirmationPassword}
                    autoComplete="new-password"
                    onChange={handleChangeConfirmationPassword}
                    className={
                      passwoedErrorMessage
                        ? "registration__input registration__input-error-border"
                        : "registration__input"
                    }
                    type={typeInputTwo ? "text" : "password"}
                    placeholder="Wachtwoord bevestigen"
                  />
                  <span
                    onClick={() =>
                      setTypeInputTwo((typeInputTwo) => !typeInputTwo)
                    }
                    className="registration__input-password-vision"
                  ></span>
                  {passwoedErrorMessage && (
                    <span className="registration__input-password-error">
                      De wachtwoorden komen niet overeen
                    </span>
                  )}
                </div>
              </div>

              <button
                disabled={
                  valueEmail.length === 0 ||
                  valuePassword.length === 0 ||
                  confirmationPassword.length === 0
                }
                onClick={handleSubmit}
                className="registration__button"
                type="submit"
              >
                Registreren
              </button>
              <div className="registration__copiright">
                Door op de registratieknop te klikken ga je akkoord met het{" "}
                <span>privacybeleid</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PreRegistration;
