import React, { useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from 'react-router-dom';

const RegistrationConfirm = () => {
    const navigate = useNavigate();
    const location = useLocation();

  useEffect(() => {
    const token = new URLSearchParams(location.search).get('token');
    console.log(token);
    const verifyEmail = async () => {
      try {
        const response = await axios.get('https://79.132.138.189:8090/api/v1/registration/registrationConfirm', {
          params: { token },
        });
        console.log(response);
        navigate("/thanks");
      } catch (error) {
        console.log(error);
        navigate("/error");
      }
    };
    verifyEmail();
  }, [navigate, location.search]);

  return (
    <section className="thanks">
      <div className="thanks__wrapper">
       <div className="word-for-confirm">EMAIL CONFIRMED</div>
      </div>
    </section>
  );
};

export default RegistrationConfirm;
