import React from "react";

const Error = () => {

  return (
    <section className="thanks">
      <div className="thanks__wrapper">
       <div className="word-for-confirm">ERROR</div>
      </div>
    </section>
  );
};

export default Error;
